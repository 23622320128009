/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp, defineAsyncComponent } from 'vue';
import Rails from 'rails-ujs';
import Vue3TouchEvents from 'vue3-touch-events';
import polyfill from '../src/polyfill/closest';
import GaUtils from '../src/utils/ga_utils';
import ImageUtils from '../src/utils/image_utils';

polyfill(window);

// NOTE: Call before main code
Rails.start();

// Init
/* eslint-disable no-new */
const app = createApp({
  components: {
    notificationToast: defineAsyncComponent(
      () => import(/* webpackChunkName: "notification_toast" */ '../src/components/users/notification_toast.vue'),
    ),
    ClickNotificationToast: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "click_notification_toast" */ '../src/components/users/click_notification_toast.vue'
        ),
    ),
    AddItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "add_item_modal" */ '../src/components/users/add_item_modal.vue'),
    ),
    RegisteredItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "registered_item_modal" */ '../src/components/users/registered_item_modal.vue'),
    ),
    ShowListModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "show_list_modal" */ '../src/components/users/show_list_modal.vue'),
    ),
    ShareListModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "share_list_modal" */ '../src/components/users/share_list_modal.vue'),
    ),
    EditItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "edit_item_modal" */ '../src/components/users/edit_item_modal.vue'),
    ),
    AddReturnModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "add_return_modal" */ '../src/components/users/add_return_modal.vue'),
    ),
    DeleteItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "delete_item_modal" */ '../src/components/users/delete_item_modal.vue'),
    ),
    SortOrderItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "sort_order_item_modal" */ '../src/components/users/sort_order_item_modal.vue'),
    ),
    EditProfileImageModal: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "edit_profile_image_modal" */ '../src/components/users/edit_profile_image_modal.vue'
        ),
    ),
    EditHeaderForm: defineAsyncComponent(
      () => import(/* webpackChunkName: "edit_header_form" */ '../src/components/users/edit_header_form.vue'),
    ),
    SendMessageForm: defineAsyncComponent(
      () => import(/* webpackChunkName: "send_message_form" */ '../src/components/users/send_message_form.vue'),
    ),
    UserProfileForm: defineAsyncComponent(
      () => import(/* webpackChunkName: "user_profile_form" */ '../src/components/users//profile_form.vue'),
    ),
    EditProfileForm: defineAsyncComponent(
      () => import(/* webpackChunkName: "user_edit_profile_form" */ '../src/components/users//edit_profile_form.vue'),
    ),
    UserAddressForm: defineAsyncComponent(
      () => import(/* webpackChunkName: "address_form" */ '../src/components/users/address_form.vue'),
    ),
    StripePayment: defineAsyncComponent(
      () => import(/* webpackChunkName: "stripe_payment" */ '../src/components/stripe_payment.vue'),
    ),
    WishlistStatus: defineAsyncComponent(
      () => import(/* webpackChunkName: "wishlist_status" */ 'src/components/users/wishlist_status.vue'),
    ),
    RenderPasswordField: defineAsyncComponent(
      () => import(/* webpackChunkName: "render_password_field" */ 'src/components/users/render_password_field.vue'),
    ),
    RequestItemModal: defineAsyncComponent(
      () => import(/* webpackChunkName: "request_item_modal" */ '../src/components/lists/request_item_modal.vue'),
    ),
    DeleteItemRequestModal: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "delete_item_request_modal" */ '../src/components/users/delete_item_request_modal.vue'
        ),
    ),
    AddItemRequestModal: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "add_item_request_modal" */ '../src/components/users/add_item_request_modal.vue'),
    ),
  },
  setup() {
    const { fitToWidthImage, fitImageSize, addFittingImageSizeClass } = ImageUtils();
    const { gaEvent } = GaUtils();

    return {
      gaEvent,
      fitToWidthImage,
      fitImageSize,
      addFittingImageSizeClass,
    };
  },
});
app.use(Vue3TouchEvents);
app.mount('#body-wrapper');

import { detectWindowSize } from 'src/utils/viewpoint_utils';

export default (): any => {
  const gaEvent = (action: string, label: string, value?: string): void => {
    (window as any).gtag('event', action, { event_category: detectWindowSize(), event_label: label, value: value });
  };
  return {
    gaEvent,
  };
};

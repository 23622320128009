enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export function detectWindowSize(): string {
  const size = window.innerWidth;
  if (size <= 768) {
    return DeviceType.Mobile;
  }
  if (size > 768 && size < 1024) {
    return DeviceType.Tablet;
  }
  return DeviceType.Desktop;
}

export function isMobile(): boolean {
  return detectWindowSize() === DeviceType.Mobile;
}

export function isTablet(): boolean {
  return detectWindowSize() === DeviceType.Tablet;
}

export function isDesktop(): boolean {
  return detectWindowSize() === DeviceType.Desktop;
}

export function enableSubmitButton(): void {
  const buttons = document.querySelectorAll('input[type="submit"]');
  for (let i = 0, l = buttons.length; i < l; i += 1) {
    buttons[i].removeAttribute('disabled');
  }
}

export function disableSubmitButton(): void {
  const buttons = document.querySelectorAll('input[type="submit"]');
  for (let i = 0, l = buttons.length; i < l; i += 1) {
    buttons[i].setAttribute('disabled', 'disabled');
  }
}

export function formCsrfToken(): string {
  const metaElement = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
  return metaElement.content;
}

export function formApiKey(): string {
  const metaElement = document.querySelector('meta[name="api-key"]') as HTMLMetaElement;
  return metaElement.content;
}

export function isNewOrCreateAction(actionName: string | undefined): boolean {
  return actionName === 'new' || actionName === 'create';
}

export function editOrUpdateAction(actionName: string | undefined): boolean {
  return actionName === 'edit' || actionName === 'update';
}

export function formMethod(actionName: string): string {
  if (actionName === 'new' || actionName === 'create') {
    return 'post';
  }
  return 'patch';
}

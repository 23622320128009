import superagent from 'superagent/dist/superagent';
import SparkMD5 from 'spark-md5';
import { ref } from 'vue';
import { disableSubmitButton, enableSubmitButton } from 'src/utils/form_utils';
import { ImageContainer } from '../types/types';

export default (propContainer?: ImageContainer, emit?: NonNullable<any>): any => {
  const isUploading = ref(false);
  const isMouseOver = ref(false);
  const isDragging = ref(false);
  const imageElementRef = ref(null);
  const ulElementRef = ref(null);
  const container = propContainer || { images: [] }; // propContainer is `reacitve` object, it's okay to mutate
  const showUploadingCircle = (): void => {
    isUploading.value = true;
  };
  const hideUploadingCircle = (): void => {
    isUploading.value = false;
  };
  const showDraggingOverlay = (): void => {
    isDragging.value = true;
  };
  const hideDraggingOverlay = (): void => {
    isDragging.value = false;
  };

  const fitImageSize = (e: Event | null, img: HTMLImageElement): void => {
    const image = img || (e.target as HTMLImageElement);
    // 実際の画像サイズで比較を行うため、一旦styleにnullを入れる
    image.style.width = null;
    image.style.height = null;
    if (image.height >= image.width) {
      image.style.width = 'auto';
      image.style.height = '100%';
    } else {
      image.style.width = '100%';
      image.style.height = 'auto';
    }
  };

  const addFittingImageSizeClass = (e: Event): void => {
    const image = e.target as HTMLImageElement;
    const picture = image.parentElement;
    const figure = picture.parentElement;
    if (image.height > image.width || image.height === image.width) {
      figure.classList.remove('is-horizontalImage');
    } else {
      figure.classList.add('is-horizontalImage');
    }
    picture.style.transition = 'opacity 300ms';
    picture.style.opacity = '1';
    figure.style.background = 'none';
  };

  const changeThumbnail = (e: Event): void => {
    // FIXME: update old code
    const mainImg = imageElementRef.value; // NOTE: old: $ref.img

    if (!mainImg.src && !mainImg.srcset) {
      return;
    }
    const currentAnchor = e.currentTarget as HTMLAnchorElement;
    const currentImg = currentAnchor.firstChild as HTMLImageElement;

    mainImg.src = currentImg.src;
    mainImg.srcset = currentImg.srcset;
    fitImageSize(null, mainImg);

    const imgsLink = (ulElementRef as unknown as Element).value.querySelectorAll('a');
    imgsLink.forEach((el: HTMLAnchorElement) => el.classList.remove('is-active'));
    currentAnchor.classList.add('is-active');
  };

  const clearSetFile = (event: Event): void => {
    // NOTE: For reattempting uploading
    const target = event.target as HTMLInputElement;
    if (target) {
      target.value = '';
    }
  };

  const clearImage = (index: number): void => {
    container.images.splice(index, 1);
  };

  // const rectangleMediumImage = (index: number) => {
  //     const str = `${container.images[index].url}=${['c', `s320`].join('-')}`;
  //     return str;
  // };

  const fitToWidthSingleImage = (image: any): string => {
    const imageUrl = image.url || '';
    if (imageUrl !== '' || imageUrl.indexOf('amazon.com') > 0 || imageUrl.indexOf('img.muji.com') > 0) {
      return imageUrl;
    }
    return `${image.url}=s320`;
  };

  const fitToWidthImage = (index: number): string => {
    const imageUrl = container.images[index].url || '';
    if (imageUrl !== '' || imageUrl.indexOf('amazon.com') > 0 || imageUrl.indexOf('img.muji.com') > 0) {
      return imageUrl;
    }
    return `${container.images[index].url}=s320`;
  };

  const changeOrder = (image: Partial<{ sort_order: number }>, direction: string): boolean => {
    const currentIndex = container.images.indexOf(image);
    if (direction === 'up') {
      if (currentIndex === 0) {
        return false;
      }
      const previmage = container.images[currentIndex - 1];
      container.images.splice(currentIndex - 1, 2, image, previmage);
    } else if (direction === 'down') {
      if (currentIndex === container.images.length - 1) {
        return false;
      }
      const nextimage = container.images[currentIndex + 1];
      container.images.splice(currentIndex, 2, nextimage, image);
    }
    for (let i = 0, l = container.images.length; i < l; i += 1) {
      container.images[i].sort_order = i + 1;
    }
    return true;
  };

  const directUploadImage = (event: Event): void => {
    const input = event.target as HTMLInputElement;
    const uploadImageFiles = Array.from(input.files);
    const maxUploadNum = 4;
    if ((container.images || []).length + uploadImageFiles.length > maxUploadNum) {
      alert(`投稿できる画像は、${maxUploadNum}枚までです。`); // eslint-disable-line no-alert
      hideUploadingCircle();
      enableSubmitButton();
      return;
    }
    uploadImageFiles.forEach((file: File) => {
      const reader = new FileReader() as any;
      const contentType = file.type;
      reader.onload = async (e: Event) => {
        const arrayBuf = e.target.result;
        (window as any).arrayBuf = arrayBuf;
        let resDirectUpload;
        try {
          resDirectUpload = await superagent
            .post(`/api/user_images/direct_upload`)
            .set('X-Csrf-Token', (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content)
            .timeout(30000)
            .send({
              checksum: btoa(SparkMD5.ArrayBuffer.hash(arrayBuf, true)),
              content_type: contentType,
            });
          hideUploadingCircle();
          emit('uploaded');
          enableSubmitButton();
        } catch (err) {
          input.files = null;
          hideUploadingCircle();
          enableSubmitButton();
        }
        const directUpload = resDirectUpload.body.direct_upload;
        await superagent
          .put(directUpload.direct_upload_url)
          .set({
            accept: 'application/xml',
            'content-type': directUpload.content_type,
            'content-md5': directUpload.checksum,
            'x-goog-acl': 'public-read',
          })
          .timeout(30000)
          .send(file.slice());
        const imageUrl = new URL(directUpload.direct_upload_url);
        imageUrl.search = '';
        if (input.multiple && container.images) {
          container.images.push({
            url: imageUrl.href,
            origin_url: imageUrl.href,
            name: directUpload.filename,
            sort_order: container.images.length + 1,
          });
        } else {
          container.image = {
            url: imageUrl.href,
            origin_url: imageUrl.href,
            name: directUpload.filename,
            sort_order: 1,
          };
        }
      };
      reader.readAsArrayBuffer(file);
    });
    emit('uploading');
    hideDraggingOverlay();
    showUploadingCircle();
    disableSubmitButton();
  };

  return {
    isUploading,
    isMouseOver,
    isDragging,
    imageElementRef,
    ulElementRef,
    showUploadingCircle,
    hideUploadingCircle,
    showDraggingOverlay,
    hideDraggingOverlay,
    fitImageSize,
    addFittingImageSizeClass,
    changeThumbnail,
    clearSetFile,
    clearImage,
    fitToWidthImage,
    fitToWidthSingleImage,
    changeOrder,
    directUploadImage,
  };
};
